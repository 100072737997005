import type { Agent } from "@/types/agent";
import type {
  AgentPaginationProps,
  CommunityAgentFilters,
  CommunityUser,
  SingleCommunityAgent,
} from "@/types/community";
import type { Conversation, Message } from "@/types/conversation";
import type { User } from "@/types/user";

export const apiPaths = {
  // GET
  user: "/user",
  getSingleAgent: (agentId: Agent["_id"]) => `/bot/bots/${agentId}`,
  getSingleAgentWithConfig: (agentId: Agent["_id"]) => `/bot/bots/${agentId}/config`,
  getAgents: "/bot/bots",
  getAgentsWithConfig: ({ page, limit }: AgentPaginationProps) =>
    `/bot/bots/config?${page ? `page=${page}&` : ""}${limit ? `limit=${limit}` : ""}`,
  getUserAgents: "/user/agents",
  getRandomAvatar: "/misc/get-random-avatar",
  getConfig: "/misc/config",
  getCategories: "/category/all",
  getSharedConversation: (conversationId: Conversation["_id"]) => `/share/conversation/${conversationId}`,
  getCommunityAgents: ({ query, category, page, limit }: CommunityAgentFilters & AgentPaginationProps) =>
    `/relation/community/agents?${query ? `query=${query}&` : ""}${category ? `category=${category}&` : ""}${page ? `page=${page}&` : ""}${limit ? `limit=${limit}` : ""}`,
  getCommunityAgentsPublic: ({ query, category, page, limit }: CommunityAgentFilters & AgentPaginationProps) =>
    `/relation/community/agents/public?${query ? `query=${query}&` : ""}${category ? `category=${category}&` : ""}${page ? `page=${page}&` : ""}${limit ? `limit=${limit}` : ""}`,
  getCommunityBasicAgents: "/relation/community/agents/basic",
  getWorkspaceDetails: "/customer",
  getWorkspaceBasicAgents: "/customer/agents/basic",
  getWorkspaceAgents: ({ page, limit }: AgentPaginationProps) =>
    `/customer/agents?${page ? `page=${page}&` : ""}${limit ? `limit=${limit}` : ""}`,
  getWorkspaceUsers: "/customer/users",
  getCommunityFeaturedAgents: "/relation/community/agents/featured",
  getCommunityFeaturedAgentsPublic: "/relation/community/agents/featured/public",
  getUserPublishedAgents: (userId: CommunityUser["_id"]) => `/relation/community/published/${userId}`,
  getConversationMessagesPaginated: ({
    agentId,
    conversationId,
    page = 1,
  }: {
    agentId: Agent["_id"];
    conversationId: Conversation["_id"];
    page: number;
  }) => `/chat/agents/${agentId}/conversations/${conversationId}?page=${page}`,
  getConversationMessagesByIndex: ({
    conversationId,
    startIndex,
    limit,
  }: {
    conversationId: Conversation["_id"];
    startIndex: number;
    limit?: number;
  }) => `/chat/conversation/${conversationId}/by-index?startIndex=${startIndex}${limit ? `&limit=${limit}` : ""}`,
  getAllConversationMessages: ({
    agentId,
    conversationId,
  }: {
    agentId: Agent["_id"];
    conversationId: Conversation["_id"];
  }) => `/chat/agents/${agentId}/conversations/${conversationId}/all`,
  getConversationSingleMessage: ({
    conversationId,
    messageId,
  }: {
    conversationId: Conversation["_id"];
    messageId: Message["_id"];
  }) => `/chat/conversation/${conversationId}/message/${messageId}`,
  getNotPublishedAgents: "/relation/community/notpublished",
  getSingleCommunityAgent: (agentId: SingleCommunityAgent["_id"]) => `/relation/community/agents/${agentId}`,
  getSingleCommunityAgentWithConfig: (agentId: SingleCommunityAgent["_id"]) =>
    `/relation/community/agents/${agentId}/config`,
  getLikedAgents: "/relation/agents/liked",
  getCommunityUser: (userId: User["_id"]) => `/user/${userId}/profile`,
  getGeneratedImage: (id: string) => `/bot/get-gen-image/${id}`,
  getAgentTools: (agentId: Agent["_id"]) => `/tools/agent/${agentId}/tools`,
  getPublicTools: "/tools/public",
  getUserTools: "/tools/user/tools",
  getToolLogs: (toolId: string) => `/tools/get-tool-logs/${toolId}`,
  getProfileFollowers: (profileId: CommunityUser["_id"]) => `/user/${profileId}/followers`,
  getProfileFollowings: (profileId: CommunityUser["_id"]) => `/user/${profileId}/following`,
  getUserMessageBookmarks: "/bookmarks/message",
  getChatHistoryWithAgents: ({ limit }: { limit?: number } = {}) => `/chat/agents${limit ? `?limit=${limit}` : ""}`,
  getAgentLeadGenerationTool: (agentId: Agent["_id"]) => `/tools/agent/${agentId}/lead-generation`,
  getSingleConversationDetails: (conversationId: Conversation["_id"]) => `/chat/conversation/${conversationId}`,
  getSingleConversationAgentWithConfig: (agentId: Agent["_id"]) => `/bot/conversation/${agentId}/config`,
  getSingleConversationAgentPublic: (agentId: Agent["_id"]) => `/bot/conversation/${agentId}/public`,
  // billing
  getBillingPlans: "/subscriptions/plans",
  getCustomerBillingInfo: "/subscriptions/billing",
  getTotalUsingCoupon: ({ planId, coupon }: { planId: string; coupon: string }) =>
    `/subscriptions/plans/${planId}/coupon/${coupon}`,
  getExternalAgents: (agentId: Agent["_id"]) => `/externalAgents/${agentId}`,
  getAllUsersConversationsWithAgent: (agentId: Agent["_id"]) => `/chat/agents/${agentId}/conversations/all`,
  getAllAgentsConversationsWithUser: (userId: User["_id"]) => `/chat/users/${userId}/conversations/all`,

  //stats
  stats: "/statistics",
  basicStats: "/statistics/basic",
  getUserTopAgentStats: "/statistics/top-agent",
  getAgentDownvotedMessages: (agentId: Agent["_id"]) => `/statistics/agents/${agentId}/downvoted`,
  getAgentsOverviewStats: "/statistics/agents-overview",
  getEngagementOverviewStats: "/statistics/engagement-overview",
  getAllAgentsStats: "/statistics/all-agents",
  getSingleAgentEngagementStats: (agentId: Agent["_id"]) => `/statistics/agents/${agentId}/engagement`,
  getAllLanguages: "/misc/languages",
  myAgentsUsersActivities: "/statistics/agents/users-activities",

  // POST
  login: "/user/login",
  register: "/user",
  resetPassword: "/user/resetPassword",
  updatePassword: "/user/updatePassword",
  createEnterpriseUser: "/user/createEnterpriseUser",
  updateAgent: "/bot/update",
  createAgent: "/bot/create",
  duplicateAgent: "/bot/duplicate",
  uploadDocument: "/bot/document",
  readDriveId: "/bot/readDriveId",
  readSitemap: "/bot/readSitemap",
  readUrls: "/bot/readUrls",
  generateNewApiKey: "/user/apikey",
  createConversation: (agentId: Agent["_id"], { isPreview }: { isPreview?: boolean } = {}) =>
    `/chat/agents/${agentId}/conversations/new${isPreview ? "?isPreview=true" : ""}`,
  createGroupConversation: "/chat/conversation/new/group",
  getAgentConversations: (agentId: Agent["_id"]) => `/chat/agents/${agentId}/conversations`,
  getAgentsGroupConversation: ({ agentsIds }: { agentsIds: string[] }) => {
    const queryString = agentsIds.join(",");

    return `/chat/agents/group-conversations?agents=${queryString}`;
  },
  getAgentPreviewConversations: (agentId: Agent["_id"]) => `/chat/agents/${agentId}/conversations/preview`,
  sendSSEMessage: (conversationId: Conversation["_id"]) => `/chat/conversations/${conversationId}/messagesse`,
  stopSSEResponse: (responseRequestId: string) => `/chat/conversations/stop/${responseRequestId}`,
  sendContactForm: "/chat/contact-form",
  shareRelation: "/relation/share",
  publishAgentToCommunity: (agentId: SingleCommunityAgent["_id"]) => `/relation/community/agent/${agentId}/publish`,
  subscribeAgent: (agentId: SingleCommunityAgent["_id"]) => `/relation/agents/${agentId}/subscribe`,
  likeAgent: (agentId: SingleCommunityAgent["_id"]) => `/relation/agents/${agentId}/like`,
  likeUser: (userId: User["_id"]) => `/relation/users/${userId}/like`,
  shareAgentByEmail: (agentId: Agent["_id"]) => `/share/agent/${agentId}`,
  shareExternalAgentByEmail: "/share/integration",
  sendHelpForm: "/user/help",
  updateTool: "/tools/update",
  createTool: "/tools/new",
  createLeadGenerationTool: "/tools/lead-generation",
  updateAgentLeadGenerationTool: (agentId: Agent["_id"]) => `/tools/agent/${agentId}/lead-generation`,
  assignToolToAgent: (agentId: Agent["_id"], toolId: string) => `/tools/agent/${agentId}/assign/${toolId}`,
  unassignToolFromAgent: (agentId: Agent["_id"], toolId: string) => `/tools/agent/${agentId}/unassign/${toolId}`,
  createUserMessageBookmark: "/bookmarks/message",
  addAgentsToConversation: ({ conversationId }: { conversationId: Conversation["_id"] }) =>
    `/chat/conversation/group/${conversationId}/agent`,
  createSubscriptionPlan: "/subscriptions/plans/new",
  updateSubscriptionPlan: "/subscriptions/plans/change",
  cancelSubscriptionPlan: "/subscriptions/plans/cancel",
  updatePaymentMethod: "/subscriptions/payment-methods/update",
  createDiscordAgent: "/externalAgents/create/discord",
  createSlackTemporaryId: "/externalAgents/createTemporaryId/slack",
  inviteUsersToWorkspace: "/customer/users",
  createAgentInteractionStat: "/statistics/agents-interaction",
  translateText: "/misc/translate",
  detectLanguage: "/misc/detect-language",
  uploadFAQFile: "/knowledges/faq-file",
  createGoogleDrive: "/externalService/create/drive",

  // PUT
  updateAgentAvatar: "/bot/avatar",
  updateName: "/user",
  updateUserAvatar: "/user/avatar",
  changeUserPassword: "/user/change-password",
  voteMessage: (messageId: Message["_id"]) => `/chat/vote/message/${messageId}`,
  editPublishedCommunityAgent: (agentId: SingleCommunityAgent["_id"]) => `/relation/community/agent/${agentId}/update`,
  uploadHelpImage: "/user/help/upload",
  updateUserMessageBookmarkPin: ({ messageId, agentId }: { messageId: string; agentId: string }) =>
    `/bookmarks/message/pin/${agentId}/${messageId}`,
  updateMessageBookmarkName: ({ messageId }: { messageId: string }) => `/bookmarks/message/${messageId}`,
  updateConversationTitle: ({ conversationId }: { conversationId: Conversation["_id"] }) =>
    `/chat/conversation/${conversationId}/title`,
  uploadFileToConversation: (conversationId: Conversation["_id"]) => `/chat/conversation/${conversationId}/files`,

  updateCustomerBillingInfo: "/subscriptions/billing",
  // PATCH
  pauseExternalAgent: "/externalAgents/pause",
  startExternalAgent: "/externalAgents/start",

  // DELETE
  deleteAgent: (agentId: Agent["_id"]) => `/bot/delete/${agentId}`,
  deleteTool: (toolId: string) => `/tools/${toolId}`,
  deleteUserMessageBookmark: ({ messageId, agentId }: { messageId: string; agentId: string }) =>
    `/bookmarks/message/${agentId}/${messageId}`,
  deleteConversation: (conversationId: Conversation["_id"]) => `/chat/conversation/${conversationId}`,
  deleteUser: "/user",
  removeAgentFromConversation: ({
    conversationId,
    agentId,
  }: {
    conversationId: Conversation["_id"];
    agentId: Conversation["_id"];
  }) => `/chat/conversation/group/${conversationId}/agent/${agentId}`,
  deletePaymentMethod: (id: string) => `/subscriptions/payment-methods/${id}`,
  deleteExternalAgent: (externalAgentId: string) => `/externalAgents/${externalAgentId}`,
  deleteUserFromWorkspace: (userId: User["_id"]) => `/customer/users/${userId}`,

  // WhatsApp
  createWhatsAppAgent: "/externalAgents/create/whatsapp",
  pauseWhatsAppAgent: "/externalAgents/unsubscribe/whatsapp",
  resumeWhatsAppAgent: "/externalAgents/subscribe/whatsapp",
  getWhatsAppAgentMessageTemplates: (phoneNumberId: string) =>
    `/externalAgents/whatsappAgent/${phoneNumberId}/message_templates`,
  postCreateWhatsAppAgentMessageTemplates: (phoneNumberId: string) =>
    `/externalAgents/whatsappAgent/${phoneNumberId}/message_templates`,
  deleteWhatsAppAgentMessageTemplates: (phoneNumberId: string) =>
    `/externalAgents/whatsappAgent/${phoneNumberId}/delete_message_template`,
} as const;
